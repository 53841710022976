<template>
  <div>
    <v-skeleton-loader v-if="loading" type="article"></v-skeleton-loader>
    <div v-else>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row align="center">
          <v-col cols="12" lg="3" sm="4">
            <v-text-field 
              label="Название программы" 
              :rules="[rules.requiredName]"
              v-model="name" 
              color="teal"/>
          </v-col>
          <v-col cols="12" lg="3" sm="4">
            <v-select
              :items="templateList"
              item-value="id"
              item-text="name"
              item-disabled="disabled"
              v-model="templateId"
              :readonly="syllabus !== null"
              label="Шаблон"
              color="teal"
              item-color="teal lighten-1"
            >
              <template #[`item`]="{ item }">
                <strong v-if="item.disabled">{{ item.name }}</strong>
                <span class="pl-3" v-else >{{ item.name }}</span>
              </template>
            </v-select>
          </v-col>
          <v-col cols="12" lg="3" sm="4">
            <v-select
              :items="users"
              :rules="[rules.requiredUser]"
              v-model="user"
              item-value="id"
              item-text="name"
              label="Пользователь"
              color="teal"
              item-color="teal lighten-1"
            ></v-select>
          </v-col>
        </v-row>
        <v-expansion-panels tile accordion class="mb-3">
          <v-expansion-panel v-for="(day, dayIndex) in days" :key="day.number" >
            <v-expansion-panel-header class="px-2 py-0">
              <template v-slot:actions class="order-1">
                <v-icon color="teal" >
                  mdi-menu-down
                </v-icon>
              </template>
              <div class="order-2 d-flex align-center justify-space-between w-100">
                <div class="ml-2">День {{ +dayIndex + 1 }} <span class="text-caption">{{ dayCaption(day) }}</span></div>
                  <v-spacer/>
                  <div>
                    <v-btn v-if="days.length > 1 && dayIndex > 0" icon @click.stop="swapDays(dayIndex - 1,dayIndex)"><v-icon>mdi-arrow-up-thin</v-icon></v-btn>
                    <v-btn v-if="days.length > 1 && dayIndex < days.length - 1" icon @click.stop="swapDays(dayIndex + 1,dayIndex)"><v-icon>mdi-arrow-down-thin</v-icon></v-btn>
                    <div v-else style="width:36px; display:inline-flex"></div>
                  </div>
                <div>
                  
                  <v-btn icon @click.stop="cloneDay(day)"><v-icon>mdi-arrange-send-backward</v-icon></v-btn>
                  <v-btn v-if="days.length > 1" icon @click.stop="removeDay(dayIndex)"><v-icon>mdi-trash-can</v-icon></v-btn>
                </div>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row v-for="(task, ind) in day.tasks" :key="day.number+'_'+ind" align="center" class="day-new-task">
                <v-col cols="12"  sm="6" class="order-1">
                  <v-select
                    v-model="task.training_id"
                    :items="trainings"
                    item-text="human_name"
                    :rules="[rules.requiredTraining]"
                    item-value="id"
                    label="Тренинг"
                    color="teal"
                    item-color="teal lighten-1"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" class="order-2">
                  <v-text-field label="Уровень. План" type="number" step="1" v-model.number="task.level_plan" color="teal"></v-text-field>
                </v-col>
                <v-col cols="12" sm="auto" class="flex-grow-1 order-3 order-sm-4 order-lg-3 py-0 ">
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-text-field label="Тренниг. Успех. Сообщение" v-model.number="task.message_success" color="teal"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field label="Тренниг. Неуспех. Сообщение" v-model.number="task.message_fail" color="teal"></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="auto" class="order-4 order-sm-3 order-lg-4 ml-auto d-flex justify-end">
                  <v-btn icon v-if="day.tasks.length > 1" @click="removeTask(dayIndex,ind)"><v-icon>mdi-trash-can</v-icon></v-btn>
                </v-col>
              </v-row>
              
              <v-btn  @click="addTask(dayIndex)" dark color="teal lighten-2" class="mb-3 mt-4">
                <v-icon left>mdi-plus-thick</v-icon>
                Добавить тренинг
              </v-btn>
              <v-row align="center">
                <v-col cols="12" sm="6">
                  <v-text-field v-model="day.message_success" label="День. Успех. Сообщение" color="teal"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field v-model="day.message_fail" label="День. Неуспех. Сообщение" color="teal"></v-text-field>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-form>
      <v-btn @click="addDay" dark color="teal lighten-2">
        <v-icon left>
          mdi-plus-thick
        </v-icon>
        Добавить день
      </v-btn>
      <v-divider class="my-5"></v-divider>
      <div class="d-flex">
        <v-btn dark color="teal" @click="save" class="mr-3">
          Сохранить
        </v-btn>
        <!-- <v-btn dark color="teal lighten-2" class="mr-3">
          Сохранить как шаблон
        </v-btn> -->
        <v-btn v-if="editId" @click="remove" dark color="red darken-2" class="mr-3">
          Удалить
        </v-btn>
        <v-btn @click="cancel" dark color="blue-grey lighten-2" class="mr-3">
          Отмена
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
 
export default {
  data: () => ({
    loading: false,
    loadingSyllabus: true,
    loadingTemplates: false,
    editId: null,
    valid: true,
    templates: [],
    days: [],
    templateId: null,
    initWitoutTemplate: false,
    syllabus: null,
    name: '',
    user: null,
    rules: {
      requiredTraining: value => !!value || 'Выберите тренинг.',
      requiredName: value => !!value || 'Введите название программы.',
      requiredUser: value => !!value || 'Выберите пользователя.',
    },
  }),
  created() {
    this.getTemplates();
    if (this.$route.params.id) {
      this.editId = +this.$route.params.id;
      this.getSyllabus()
    } else {
      this.initWitoutTemplate = true;
      this.addDay();
    }
  },
  watch: {
    loadingData() {
      if (this.loadingData && this.templateId != this.syllabus.templateId) {
        let template = this.templates.find(t => t.id == this.templateId);
        if (template && template.days) {
          this.days = [...template.days];
        } else {
          this.days = [];
          this.addDay();
        }
      }      
    },
    templateId() {
      if (!this.$route.params.id) {
        let template = this.templates.find(t => t.id == this.templateId);
        if (template) {
          this.days = [...template.days];
        }
      }
    }
  },
  computed: {
    trainings() {
      return this.$store.state.trainings;
    },
    templateGroups() {
      let groups = {};
      this.templates.forEach(t => {
        groups[t.group.id || 0] = t.group
      });

      return Object.values(groups);
    },
    templateList() {
      let items = [];
      this.templateGroups.forEach((g) => {
        if (g.id) {
          items.push({...g, disabled: true});
        }
        items = items.concat(this.templates.filter(t => t.group.id == g.id).map(t => {return {...t, disabled: false }}));
      });
      return items;
    },
    loadingData() {
      return this.loadingSyllabus && this.loadingTemplates;
    },
    users() {
      return this.$store.state.users;
    }
  },
  methods: {
    addDay() {
      let count = this.days.length;
      let day = {
        tasks: [],
        message_success: '',
        message_fail: ''
      }
      this.$set(this.days, count, day);
      this.addTask(count);
    },
    cloneDay(day) {
      let count = this.days.length;
      let newDay = {
        tasks: day.tasks.map(t => {return {...t}}),
        message_success: day.message_success,
        message_fail: day.message_fail,
      }
      this.$set(this.days, count, newDay);
      console.log(day);
    },
    removeDay(dayIndex) {
      this.days.splice(dayIndex,1)
    },
    swapDays: function (firstKey,secondKey) {
        let tempDay = this.days[firstKey];
        this.$set(this.days, firstKey, this.days[secondKey]);
        this.$set(this.days, secondKey, tempDay);
    },
    addTask(dayIndex) {
      let training = this.trainings[0];
      let training_id = null;
      if (training) {
        training_id = training.id;
      }

      let task = { training_id, level_plan: '', message_success:'',message_fail:''}
      this.$set(this.days[dayIndex].tasks, this.days[dayIndex].tasks.length, task);
      
    },
    removeTask(dayIndex,ind) {
      this.days[dayIndex].tasks.splice(ind,1)
    },
    getSyllabus() {
      this.loading = true;
      this.loadingSyllabus = false;
      let url = `/syllabi/${this.editId}/edit`;
       this.$api.get(url)
        .then(resp => {
          this.syllabus = resp.data._embedded.syllabus;
          this.templateId = resp.data._embedded.syllabus.template_id;
          this.name = resp.data._embedded.syllabus.name;
          this.user = resp.data._embedded.syllabus.user_id;
          this.days = resp.data._embedded.syllabus.days;
          this.loading = false;
          this.loadingSyllabus = true;
        })
        .catch(error => {           
            this.$store.dispatch('addRequestError', error)
        })
    },
    getTemplates() {
      let url = '/templates';
       this.$api.get(url)
        .then(resp => {
          this.templates = resp.data._embedded.templates;
          this.sortTemplates();
        })
        .catch(error => {           
            this.$store.dispatch('addRequestError', error)
        })
    },
    sortTemplates() {
      this.templates.sort((a,b) => (a.group.id > b.group.id) ? 1 : ((b.group.id > a.group.id) ? -1 : 0));
    },
    save() {
      if (this.$refs.form.validate()) {
        if (this.editId) {
          this.patch()
        } else {
          this.store()
        }
      }
    },
    store() {
      if (this.$refs.form.validate()) {
        let url = '/syllabi'
        this.$api.post(url,{days:this.days, user_id: this.user, name: this.name, template_id: this.templateId })
          .then(resp => {
            let id = resp.data._embedded.id;
            this.$store.dispatch('addMessage', 'Программа сохранена')
            this.$router.push({ path: `/syllabi/${id}/edit` })
          })
          .catch(error => {     
            console.log(error);      
            this.$store.dispatch('addRequestError', error)
          })
      }
    },
    patch() {
      if (this.$refs.form.validate()) {
        let url = `/syllabi/${this.editId}`
         this.$api.patch(url,{days:this.days, user_id: this.user, name: this.name, template_id: this.templateId })
          .then(() => {
            this.getSyllabus();
            this.$store.dispatch('addMessage', 'Изменения сохранены')
          })
          .catch(error => {           
              this.$store.dispatch('addRequestError', error)
          })
      }
    },
    remove() {
      let url = `/syllabi/${this.editId}`
      this.$api.delete(url)
        .then(() => {       
          this.$router.push({ path: `/syllabi` })   
          this.$store.dispatch('addMessage', 'Программа отправлена в архив')
        })
        .catch(error => {           
            this.$store.dispatch('addRequestError', error)
        })
      
    },
    cancel() {
      this.$router.push({ path: `/syllabi` })
    },
    dayCaption(day) {
      return day.tasks.map(t => {
        let training = this.trainings.find(tr => tr.id == t.training_id);
        let text = '';
        if (training) {
          text = training.group.name.substr(0,3) + '. ';
          text += training.name;
        }
        if (t.level_plan) {
          text += ' - '+t.level_plan;
        }
        return text }
        ).join(', ');
    }
  }
}
</script>
